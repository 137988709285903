<template>
  <div class="course">
    <div class="container-fluid course-banner">
      <div class="container">
        <div class="row">
          <div class="col-md-7" v-if="isCourseLoader == true">
            <content-loader></content-loader>
          </div>
          <div class="col-md-7" v-else>
            <h3>
              {{ course.title }}
            </h3>
            <p>
              {{ course.short_description }}
            </p>
            <p class="includes">
              <span>{{ subjects.length }} Subjects</span>
            </p>
            <div class="button-group">
              <span
                class="badge"
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
                >{{ subject.title }}</span
              >
            </div>
            <div>
              <ul class="list-unstyled">
                <li
                  v-for="(feature, featureIndex) in course.features"
                  :key="featureIndex"
                >
                  {{ feature.feature }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4">
            <div class="course-card">
              <div class="play-wrapper">
                <img src="../assets/webinar-plc.png" alt="" />
                <button class="btn">
                  <i class="fas fa-play-circle"></i>
                </button>
              </div>
              <div class="price-box" v-if="isCourseLoader == false">
                <h2>₹{{ offerPrice }}</h2>
                <h6 style="color: #710101" v-if="price.offer > 0">
                  <s>₹{{ price.price }}</s>
                </h6>
                <span class="tag" v-if="price.offer > 0"
                  >{{ price.offer }}% OFF</span
                >
                <!-- <i class="far fa-heart"></i>
                <h6>{{price.price}} </h6> -->
              </div>
              <div class="price-box" v-else>
                <circular-loader></circular-loader>
              </div>
              <router-link
                v-if="this.course.is_buyable == true"
                :to="{ name: 'courseCheckout', params: { slug: course.slug } }"
                class="btn cta-primary"
                >Buy now</router-link
              >
              <div
                class="col-md-6 btn btn-primary disabled"
                v-else-if="this.course.is_buyable == false"
              >
                Purchase Date Expired.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="description-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-7 text-col">
            <h3>Description</h3>
            <p v-html="course.description"></p>
          </div>
          <div class="col-md-5">
            <img src="../assets/description.png" alt="" />
          </div>
        </div>
        <!-- <div class="points-wrapper">
          
        </div> -->
      </div>
    </div>

    <div class="highlights-wrapper">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h4>What you'll learn</h4>
            <ul class="list-unstyled">
              <li
                v-for="(learn, learnIndex) in course.what_will_you_learn"
                :key="learnIndex"
              >
                {{ learn.feature }}
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <img src="../assets/trophy.svg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="session-wrapper">
      <div class="container">
        <h3 class="py-3 text-center" style="color: #fff;">Sessions</h3>
      </div>
      <div class="container card-wrapper">
        <div
          class="session-card"
          v-for="(subject, sessionIndex) in subjects.slice(0, 4)"
          :key="sessionIndex"
        >
          <div class="session-inner">
            <img :src="subject.image" alt="subject image" />
            <h5>{{ subject.title }}</h5>
            <p v-html="subject.description"></p>
            <button class="btn cta-primary">Details</button>
          </div>
        </div>
      </div>
    </div>

    <div class="review-wrapper">
      <div class="container text-center mb-4">
        <h4 style="color: #8980b7;">
          Reviews
        </h4>
      </div>
      <div class="container">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <!-- <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div> -->
          <div class="carousel-inner">
            <div
              v-for="(testimonial, testimonialIndex) in testimonials"
              :key="testimonialIndex"
              class="carousel-item"
              :class="testimonialIndex == 0 ? 'active' : ''"
            >
              <div class="row align-items-center review-slide">
                <div class="col-md-6 img-wrap">
                  <img :src="testimonial.image" alt="user image" />
                </div>
                <div class="col-md-6">
                  <div class="name">
                    <h6>
                      {{ testimonial.first_name }} {{ testimonial.last_name }}
                    </h6>
                  </div>
                  <p class="rating">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </p>
                  <p>
                    {{ testimonial.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="faq-wrapper">
      <div class="container">
        <h4>Frequently asked questions</h4>

        <div class="accordion" id="accordionExample">
          <div
            class="accordion-item"
            v-for="(faq, faqIndex) in faqs"
            :key="faqIndex"
          >
            <h2 class="accordion-header" :id="'heading' + faq.id">
              <button
                class="accordion-button"
                :class="faqIndex == 0 ? '' : 'collapsed'"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + faq.id"
                :aria-expanded="faqIndex == 0 ? 'true' : 'false'"
                :aria-controls="'collapse' + faq.id"
              >
                {{ faq.question }}
              </button>
            </h2>
            <div
              :id="'collapse' + faq.id"
              class="accordion-collapse collapse"
              :class="faqIndex == 0 ? 'show' : ''"
              :aria-labelledby="'heading' + faq.id"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="subscribe-wrapper container-fluid">
      <div class="container">
        <h3>get to Know us better</h3>
        <p>subscribe to newsletter</p>
        <div class="subscribe-field">
          <div class="input-field">
            <form class="d-flex">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn cta-primary" type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService.js";
import errorLog from "@/errorLog";
import ContentLoader from "@/components/loaders/ContentLoader";
import CircularLoader from "@/components/loaders/CircularLoader";
export default {
  name: "Course",
  components: {
    ContentLoader,
    CircularLoader
  },
  created() {
    this.getProgram();
    this.getFaq();
    this.loadTestimonials();
  },
  data() {
    return {
      testimonials: [],
      faqs: [],
      course: [],
      isCourseLoader: true,
      subjects: [],
      price: [],
      slug: this.$route.params.slug
    };
  },
  computed: {
    offerPrice: function() {
      return (
        parseFloat(this.price.price) -
        (parseFloat(this.price.price) * parseFloat(this.price.offer)) / 100
      );
    }
  },
  methods: {
    async getProgram() {
      await ProgramService.getProgram({
        slug: this.slug
      })
        .then(result => {
          this.isCourseLoader = false;
          this.course = result.data.program;
          this.price = result.data.price;
          if (this.price.offer == null) {
            this.price.offer = 0;
          }
          this.subjects = this.course.subjects;
        })
        .catch(error => {
          this.isClassLoader = false;
          errorLog.log(error);
        });
    },
    loadTestimonials() {
      this.isTestimonialsLoader = true;
      ProgramService.getTestimonials()
        .then(result => {
          this.isTestimonialsLoader = false;
          this.testimonials = result.data.data;
        })
        .catch(error => {
          this.isTestimonialsLoader = false;
          errorLog.log(error);
        });
    },
    getFaq() {
      this.isFaqsLoader = true;
      ProgramService.getFaqs()
        .then(result => {
          this.isFaqsLoader = false;
          this.faqs = result.data.data;
        })
        .catch(error => {
          this.isFaqsLoader = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/course.scss";
</style>
