<template>
  <img src="../../assets/contentLoader.svg" class="loader mx-auto d-block" />
</template>
<script>
export default {
  name: "ContentLoader",
  components: {}
};
</script>

<style scoped>
img.loader.mx-auto.d-block {
  opacity: 0.3;
  width: 75%;
}
</style>
